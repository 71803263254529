$base-color:#000;
$text-color:#fff;
$accent-color: #A04D9A;

.hide-mobile {}

#root{
  -webkit-user-select: none;
}

.mobile-only {
  display: none !important;
}

.background {
  display: flex;
  background: $base-color;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center
}

.base-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.scanner-container{
  flex: 1;
  display:flex;
  justify-content:center;
  align-items:center;
  background:$base-color;
  .scanner{
    width: 500px;
  }
}



.vatom-grid {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  overflow-x: auto;
  justify-content: flex-start;
  .vatom-container{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    margin: 20px 0;
    position: relative;
    transition: transform 0.01s;
    cursor: pointer;
  }
  .combine-overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    pointer-events: none;
    color:$text-color;
    display:flex;
    justify-content:center;
    align-items:center
  }
  .vatom-icon-image{
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    pointer-events: none;
    display:block;
  }
  .vatom-icon-image.hide{
    display: none;
  }
  .vatom-icon-3dmodel{
    pointer-events:none;
  }
  .vatom-icon-progress-container{
    pointer-events:none;
    .vatom-icon-progress-base{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .vatom-icon-progress-layer-container{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .vatom-icon-progress-layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

      }
    }
  }
}

.empty-state-container{
  display:flex;
  width:100%;
  height:100%;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  p{
    max-width:650px;
    text-align:center;
    font-size:28px;
    color:#bababa;
  }
  a{
    text-decoration: none;
    font-weight: bolder;
    color:#bababa;
  }
  img{
    max-width: 80%;
    object-fit: contain;
  }
}

.vatom-fullscreen-container{
  flex: 1;
  display:flex;
  justify-content:center;
  position:relative;
  .vatom-actions-container{
    position:absolute;
    right:15px;
    top:15px;
    background:$text-color;
  }
  img.vatom-viz{
    object-fit: contain;
  }
  .vatom-viz{
    flex:1;
    border:0;
    max-width:550px;
    // object-fit:contain;
  }
}
.vatom-grid.loading{
  align-items: center;
  justify-content: center;
}

.login-wrapper {
  background: $text-color;
  width: 700px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  flex-direction: column;
  padding: 30px;

  img {
    width: 150px;
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    button {
      margin-bottom: 10px;
      width: 300px;
      color: $text-color;
      background: $accent-color;

      :hover {
        color: $accent-color;
        border: 1px solid $accent-color;
      }
    }

    .input,
    .react-tel-input {
      margin-bottom: 10px;
      width: 300px;
    }

    a {
      cursor: pointer;
      margin-bottom: 10;
      text-align: center;
    }
  }

  .login-picker {
    display: flex;
    align-items: center;

    span {
      margin-right: 30px;
    }

    .dropdown {
      width: 200px;
    }
  }
}

#footer{
  background:$base-color;
  padding: 15px;
  color: $text-color;
  display:flex;
  align-items:center;
  justify-content:center;
}

.loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-nav {
  position: fixed;
  background: $base-color;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  z-index: 100;
  padding: 15px;
  flex-direction: column;
  justify-content: space-evenly;
  a{
    display: flex;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    text-decoration: none;
    color: $text-color;
  }
  div{
    position: absolute;
    top: 25px;
    right: 15px;
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in;
}

.actions-icon {
  font-size: 30px !important;
  margin: 5px;
  cursor: pointer;
  color: #999;
}
.actions-icon.black{
  color:$base-color;
  font-size:30px;
}

.profile-container{
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  .info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }
    .text-field-container{
      margin-top: 15px;
      margin-bottom: 15pc;
      display: flex;
      justify-content: space-between;
    }
    .token-container{
      margin-top: 15px;
    }
    a{
      cursor: pointer;
    }
  }
}

.transfer-modal{
  form{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    .input,.react-tel-input{
      margin-bottom: 10px;
      width: 300px;
    }
    button{
      margin-bottom: 10px;
      width: 300px;
      color: $text-color;
      background: $accent-color;
    }
  }
}

.custom-modal{
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-modal-container{
    background: $text-color;
    text-align: center;
    border: none!important;
    padding: 50px;
    position:relative;
  }
}
 .threed-container{
  flex:1;
  display: none;
 }

 .threed-container.visible{
   display: inherit;
 }

.drop-button{
  margin-top: 10px;
  width: 300px;
  color: $text-color;
  background: $accent-color;
}

.vatom-icon-map{
  display:flex;
  height:60px;
  width:60px;
  img{
    max-width:100%;
    max-height:100%;
    cursor:pointer;
    object-fit:contain;
    display: block;
  }
  img.loading{
    display: none;
  }
}

#navbar {
  background: $base-color;
  padding: 15px 50px;
  color: $text-color;
  display: flex;

  div {
    flex: 1;
  }

  img {
    width: 150px;
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    a {
      cursor: pointer;
      text-decoration: none;
      color: $text-color;
      text-align: center;
      span{
        display: block;
      }
    }
  }

  .active {
    font-weight: bold;
  }

  .profile-link{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a{
      cursor: pointer;
      text-decoration: none;
      color: $text-color;
    }
  }

}


.loader-inner.pacman {
  div:first-of-type {
    z-index: 2;
  }

  div:nth-child(2) {
    z-index: 2;
  }

  div:nth-child(3) {
    background-color: $accent-color;
  }

  div:nth-child(4) {
    background-color: #3345DA;
  }

  div:nth-child(5) {
    background-color: #E2E2EA;
  }

  div:nth-child(6) {
    background-color: #383838;
  }
}

.loader-inner.pacman.dark {
  div:first-of-type {
    border-top: 25px solid $base-color;
    border-left: 25px solid $base-color;
    border-bottom: 25px solid $base-color;
  }

  div:nth-child(2) {
    border-top: 25px solid $base-color;
    border-left: 25px solid $base-color;
    border-bottom: 25px solid $base-color;
  }
}

.dragging {
  transform: translateX(-999999px);
  opacity: 1;
}

@media only screen and (max-width: 720px) {
  #navbar {
    padding: 15px !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .mobile-only {
    display: inherit !important;
  }

  .dragging {
    transform: inherit;
  }

  .empty-state-container{
    img{
      max-width: 50%;
    }
    p{
      max-width: 80%;
      font-size: 24px;
    }
  }
}
